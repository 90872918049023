import { CameraOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spin,
  Tag,
  message,
} from "antd";
import { Tabs } from "antd";
import Title from "antd/lib/typography/Title";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useMatch } from "react-router-dom";

import { accountProfilePhoto } from "@/app/local/accountSlice";
import { getMerchantDetail } from "@/app/local/adminMerchantSlice";
import { getPublishDetail } from "@/app/local/adminPublisherSlice";
import { setUserStatus } from "@/app/local/adminUserSlice";
import { appUploadFile, getFile } from "@/app/local/appSlice";
import ProfileImage from "@/assets/images/profile.png";
import MainContainer from "@/components/container/MainContainer";
import AccessManagement from "@/components/extra/AccessManagement";
import ContractUser from "@/components/extra/ContractUser";
import ServiceSetting from "@/components/extra/ServiceSetting";
import FormKyc from "@/components/forms/FormKyc";
import FormStatusContract from "@/components/forms/FormStatusContract";
import FormStatusUser from "@/components/forms/FormStatusUser";
import FormUpdateLegalUser from "@/components/forms/FormUpdateLegalUser";
import FormUpdatePassword from "@/components/forms/FormUpdatePassword";
import FabIcon from "@/components/general/FabIcon";
import KYCAlert from "@/components/general/KYCAlert";
import PageTitle from "@/components/general/PageTitle";
import SliderMenu from "@/components/general/SliderMenu";
import StepCompanyInfo from "@/components/userInfo/StepCompanyInfo";
import StepContactInfo from "@/components/userInfo/StepContactInfo";
import StepPaymentInfo from "@/components/userInfo/StepPaymentInfo";
import StepPersonalInfo from "@/components/userInfo/StepPersonalInfo";
import StepTaxInfo from "@/components/userInfo/StepTaxInfo";
import { Privileges } from "@/utils/constants/accessCenter";
import {
  CONTRACT_STATUS,
  CONTRACT_STATUS_ALERT,
  FILE_FILE_TYPE,
  FILE_MEDIA_TYPE,
  FILE_OBJECT_TYPE,
  LEGAL_STATUS,
  USER_STATUS,
  USER_STATUS_ALERT,
  USER_TYPE,
} from "@/utils/constants/apiConstants";
import {
  customUploadFile,
  formatString,
  getContractStatus,
  getUrlFile,
  getUserStatus,
  hasAccess,
  objectR,
} from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";

import "./styles.less";

const { TabPane } = Tabs;

export const USER_PAGE = {
  publishers: "publishers",
  merchants: "merchants",
};

export const getProfileData = () => {
  const location = useLocation();
  const usrType = getUserType(location);
  const isProfile = useMatch("/profile");
  const isWizard = useMatch("/wizard");

  if (!isProfile && !isWizard) {
    if (usrType.type === USER_PAGE.publishers) {
      const { user } = useSelector((state) => state.adminPublisher);
      return {
        userDetail: user?.data,
        data: user?.data?.user,
      };
    } else if (usrType.type === USER_PAGE.merchants) {
      const { user } = useSelector((state) => state.adminMerchant);

      return {
        userDetail: user?.data,
        data: user?.data?.user,
      };
    }
  } else {
    const { profile } = useSelector((state) => state.account);
    return {
      userDetail: profile?.userDetail?.data,
      data: profile?.data,
    };
  }
};

export const isUserPage = () => {
  const location = useLocation();
  const usrType = getUserType(location);

  return useMatch(`/${usrType?.type}/:id`);
};

export const getUserType = (location) => {
  const pth = location.pathname;
  if (pth.match(/[0-9]+/gm)) {
    const [id] = pth.match(/[0-9]+/gm);

    if (pth.includes(USER_PAGE.publishers)) {
      return {
        type: USER_PAGE.publishers,
        id,
      };
    }
    if (pth.includes(USER_PAGE.merchants)) {
      return {
        type: USER_PAGE.merchants,
        id,
      };
    }
  }
};

export default function Profile() {
  const dispatch = useDispatch();
  const location = useLocation();
  const usrType = getUserType(location);
  const isProfile = useMatch(`/profile`);
  const isUserPage = useMatch(`/${usrType?.type}/:id`);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [defaultTab, setDefaultTab] = useState("1");
  const imgUploadRef = useRef();
  const [profileImage, setProfileImage] = useState(ProfileImage);
  const { user, isAdmin, isAdvertiser } = useAccount();
  const isMobile = useDeviceDetector();

  const [menuOpen, setOpenMenu] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const { loadingFile } = useSelector((state) => state.app);
  const { loading: L0 } = useSelector((state) => state.adminMerchant);
  const { loading: L1 } = useSelector((state) => state.adminPublisher);
  const { loading: L2 } = useSelector((state) => state.account);
  const loading = L0 || L1 || L2;

  useEffect(() => {
    if (!isProfile) {
      if (usrType.type === USER_PAGE.publishers) {
        dispatch(getPublishDetail(isUserPage.params.id));
      } else {
        dispatch(getMerchantDetail(isUserPage.params.id));
      }
    } else {
      if (location.search === "?tabId=contract") {
        setDefaultTab("6");
      }
    }
  }, [isProfile, forceUpdate]);

  const [isLegal, setIsLegal] = useState(false);
  const profile = getProfileData();

  const userType = _.get(profile, "data.user_type");
  const userInfo = _.get(profile, "userDetail");
  const contractStatus = _.get(profile, "data.contract_status");

  const uStatus = userInfo?.user?.status;
  const userStatus = objectR(USER_STATUS)[uStatus];
  const contStatus = objectR(CONTRACT_STATUS)[userInfo?.user?.contract_status];

  useEffect(() => {
    // automate change to InReview
    if (isUserPage) {
      if (uStatus === USER_STATUS.PendingForApproval) {
        const uId = isUserPage.params?.id;
        const setUserStatusAsync = async () => {
          const res = await dispatch(
            setUserStatus({
              user_id: uId,
              status: USER_STATUS.InReview,
              description: "",
            })
          );
          if (res?.payload.success) {
            setForceUpdate(true);
          }
        };
        setUserStatusAsync();
      }
    }

    const setImageProfileAsync = async () => {
      const pFileId = userInfo?.user?.profile_file_id;
      if (userInfo && pFileId) {
        const resultFile = await dispatch(getFile(pFileId));
        const fileData = _.get(resultFile, "payload.data");
        if (fileData) {
          const imageUrl = getUrlFile(fileData);
          setProfileImage(imageUrl);
        }
      }
    };
    setImageProfileAsync();

    if (userType === USER_TYPE.MERCHANT) {
      setIsLegal(true);
    } else {
      if (userInfo?.legal_status === LEGAL_STATUS.LEGAL) {
        setIsLegal(true);
      } else {
        setIsLegal(false);
      }
    }
  }, [userInfo]);

  function callback(key) {
    setDefaultTab(key);
  }

  const changeProfile = () => {
    if (!isUserPage) imgUploadRef.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    const fileInfo = customUploadFile(
      fileUploaded,
      FILE_FILE_TYPE.PROFILE_IMAGE,
      FILE_MEDIA_TYPE.IMAGE,
      userInfo?.user_id,
      FILE_OBJECT_TYPE.USER
    );

    const response = dispatch(appUploadFile(fileInfo));
    response
      .then(async (r) => {
        if (r?.payload?.success) {
          const fileId = _.get(r, "payload.data.file_id");
          const result = await dispatch(accountProfilePhoto(fileId));
          if (result?.payload?.success) {
            const imageUrl = getUrlFile(r?.payload?.data);

            setProfileImage(imageUrl);
          } else {
            message.error({
              content: t("general.unHandleErrorMessage"),
            });
          }
        } else {
          message.error({
            content: t("general.unHandleErrorMessage"),
          });
        }
      })
      .catch(() => {
        const error = new Error("Some error");
        // onError({ error });
      });
  };

  return (
    <div className="profile">
      {/* <div className="header"> */}
      <PageTitle
        tour_id={"profile-title"}
        title={isUserPage ? "UserDetail" : "Profile"}
      >
        {isAdmin && userInfo?.user?.old_user_id && (
          <Button type="default" onClick={setModalOpen} block={isMobile}>
            {t("profile.showOldUser")}
          </Button>
        )}
      </PageTitle>

      {uStatus === USER_STATUS.Confirmed &&
      isProfile &&
      contractStatus === CONTRACT_STATUS.NEW ? (
        <div style={{ margin: "16px", marginBottom: "0px" }}>
          <Alert
            className="contract_alert"
            showIcon
            type="warning"
            message={<b>{t("auth.contract_alert")}</b>}
          ></Alert>
          <br />
        </div>
      ) : (
        ""
      )}
      {/* </div> */}
      <MainContainer wrapDisabled>
        <Row gutter={[16, 16]} justify="">
          <Col md={18} xs={24}>
            <KYCAlert />
            <Card style={{ marginTop: 0 }} loading={loading}>
              <Tabs
                defaultActiveKey={"1"}
                activeKey={defaultTab}
                onChange={callback}
              >
                <TabPane
                  tab={t(
                    !isLegal ? "wizard.stepPersonal" : "wizard.stepCompany"
                  )}
                  key="1"
                >
                  {!isLegal ? (
                    <StepPersonalInfo md={24} />
                  ) : (
                    <StepCompanyInfo md={24} />
                  )}
                </TabPane>

                <TabPane tab={t("wizard.stepContact")} key="2">
                  <StepContactInfo md={24} />
                </TabPane>

                {userType === USER_TYPE.PUBLISHER ? (
                  <>
                    <TabPane tab={t("wizard.stepPayment")} key="3">
                      <StepPaymentInfo md={24} />
                    </TabPane>
                    {isLegal && (
                      <TabPane tab={t("wizard.stepTax")} key="4">
                        <StepTaxInfo md={24} />
                      </TabPane>
                    )}
                  </>
                ) : (
                  ""
                )}
                {userType === USER_TYPE.MERCHANT ? (
                  <TabPane tab={t("wizard.stepTax")} key="3">
                    <StepTaxInfo md={24} />
                  </TabPane>
                ) : (
                  ""
                )}
                <TabPane tab={t("wizard.securityConfig")} key="5">
                  <FormUpdatePassword
                    userType={userType}
                    userInfo={userInfo}
                    isChangeAdmin={true}
                  />
                </TabPane>

                <TabPane
                  disabled={uStatus !== USER_STATUS.Confirmed && isProfile}
                  tab={
                    <div tour_id={"contract-tab"}>{t("wizard.contract")}</div>
                  }
                  key="6"
                >
                  <ContractUser />
                </TabPane>

                {!isAdvertiser && userType !== USER_TYPE.MERCHANT ? (
                  <TabPane tab={t("wizard.serviceSetting")} key="8">
                    <ServiceSetting />
                  </TabPane>
                ) : (
                  ""
                )}

                {isUserPage &&
                hasAccess(user, [Privileges.SETTING.ACCESS_MGM_VIEW]) ? (
                  <TabPane
                    disabled={uStatus !== USER_STATUS.Confirmed && isProfile}
                    tab={t("profile.accessManagement")}
                    key="7"
                  >
                    <AccessManagement data={profile} />
                  </TabPane>
                ) : (
                  ""
                )}
              </Tabs>
            </Card>
          </Col>
          <Col md={6} xs={24}>
            <Card loading={loading}>
              <div className="profile__avatar">
                <input
                  type="file"
                  ref={imgUploadRef}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <div className="af_avatar" onClick={changeProfile}>
                  <Spin spinning={loadingFile}>
                    <Avatar
                      src={profileImage}
                      style={{ backgroundColor: "#dee5f0" }}
                    ></Avatar>
                  </Spin>
                  <div className="af_avatar__icon">
                    <CameraOutlined className="af_avatar__icon--ico" />
                  </div>
                </div>
                <Title level={5}>
                  {!isLegal
                    ? `${userInfo?.first_name} ${userInfo?.last_name}`
                    : `${userInfo?.company_name}`}
                </Title>
                {isAdmin ? (
                  <p>
                    {t("user.id")}: <Tag>{userInfo?.user_id}</Tag>
                  </p>
                ) : (
                  ""
                )}
                <p className="profile__phone">
                  {formatString(userInfo?.user?.mobile, "mobile")}
                </p>
              </div>
              <div className="profile__info">
                <ul className="profile__info--ul">
                  <li>
                    <span>{t("profile.userState")}</span>
                    <span>
                      <Tag color={USER_STATUS_ALERT[userStatus]}>
                        {t(
                          "profile.user." +
                            getUserStatus(userInfo?.user?.status)
                        )}
                      </Tag>
                    </span>
                  </li>
                  <li>
                    <span>{t("profile.contractState")}</span>
                    <span>
                      <Tag color={CONTRACT_STATUS_ALERT[contStatus]}>
                        {t(
                          "profile.contract." +
                            getContractStatus(userInfo?.user?.contract_status)
                        )}
                      </Tag>
                    </span>
                  </li>
                </ul>
              </div>
            </Card>
          </Col>
        </Row>
      </MainContainer>

      {!isProfile ? (
        <>
          <SliderMenu
            title={menuOpen?.title}
            collapsed={menuOpen}
            setCollapsed={setOpenMenu}
          >
            {menuOpen?.key === "KYC_FORM" ? (
              <FormKyc close={() => setOpenMenu()} userInfo={userInfo} />
            ) : (
              ""
            )}
            {menuOpen?.key === "USER_STATUS_FORM" ? (
              <FormStatusUser close={() => setOpenMenu()} userInfo={userInfo} />
            ) : (
              ""
            )}
            {menuOpen?.key === "USER_CONTRACT_STATUS" ? (
              <FormStatusContract
                close={() => setOpenMenu()}
                userInfo={userInfo}
              />
            ) : (
              ""
            )}
            {menuOpen?.key === "USER_LEGAL_CHANGE" ? (
              <FormUpdateLegalUser
                close={() => setOpenMenu()}
                userInfo={userInfo}
              />
            ) : (
              ""
            )}
          </SliderMenu>
          <FabIcon
            setCollapsed={setOpenMenu}
            items={[
              {
                title: "users.StatusDocumentUser",
                key: "KYC_FORM",
                type: "LEFT_MENU",
              },
              {
                title: "users.StatusUserForm",
                key: "USER_STATUS_FORM",
                type: "LEFT_MENU",
              },
              {
                title: "users.StatusContract",
                key: "USER_CONTRACT_STATUS",
                type: "LEFT_MENU",
              },
              userType === USER_TYPE.PUBLISHER
                ? {
                    title: "users.ChangeLegalStatus",
                    key: "USER_LEGAL_CHANGE",
                    type: "LEFT_MENU",
                  }
                : undefined,
            ]}
          />
        </>
      ) : (
        ""
      )}

      <Modal
        title={t("profile.showOldUser")}
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        okText={t("users.close")}
      >
        <Row gutter={(12, 50)}>
          <Col md={19} xs={19}>
            {t("user.oldUserId")}: <b>{userInfo?.user?.old_user_id}</b>
          </Col>

          <Col md={19} xs={19}>
            {t("user.oldUsername")}: <b>{userInfo?.user?.old_username}</b>
          </Col>

          <Col md={19} xs={19}>
            {t("user.picNationalCode")}
          </Col>
          <Col md={5} xs={5}>
            {userInfo?.old_birth_Certificate_file_Path ? (
              <a
                target="_blank"
                href={userInfo?.old_birth_Certificate_file_Path}
                rel="noreferrer"
              >
                {t("general.view")}
              </a>
            ) : (
              t("general.noData")
            )}
          </Col>
          <Col md={19} xs={19}>
            {t("user.picIdentityNumber")}
          </Col>
          <Col md={5} xs={5}>
            {userInfo?.old_national_card_file_path ? (
              <a
                target="_blank"
                href={userInfo?.old_national_card_file_path}
                rel="noreferrer"
              >
                {t("general.view")}
              </a>
            ) : (
              t("general.noData")
            )}
          </Col>
          <Col md={19} xs={19}>
            {t("user.picPaperDocument")}
          </Col>
          <Col md={5} xs={5}>
            {userInfo?.old_news_paper_document_path ? (
              <a
                target="_blank"
                href={userInfo?.old_news_paper_document_path}
                rel="noreferrer"
              >
                {t("general.view")}
              </a>
            ) : (
              t("general.noData")
            )}
          </Col>
          <Col md={19} xs={19}>
            {t("user.picVatDocument")}
          </Col>
          <Col md={5} xs={5}>
            {userInfo?.old_vat_file_path ? (
              <a
                target="_blank"
                href={userInfo?.old_vat_file_path}
                rel="noreferrer"
              >
                {t("general.view")}
              </a>
            ) : (
              t("general.noData")
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

const ButtonStatus = (props) => {
  let status, type;
  if (!props.status) return null;
  try {
    if (props.type === "USER") {
      status = Object.entries(USER_STATUS).find((m) => m[1] === props.status);
      type = USER_STATUS_ALERT[status[0]];
    } else if (props.type === "CONTRACT") {
      status = Object.entries(CONTRACT_STATUS).find(
        (m) => m[1] === props.status
      );
      type = CONTRACT_STATUS_ALERT[status[0]];
    }
  } catch (err) {
    const catching = "catch";
  }

  if (type === "success")
    return (
      <Button
        className="btn-status btn-status__success"
        shape="round"
        type="ghost"
      >
        {props.children}
      </Button>
    );
  if (type === "danger")
    return (
      <Button
        className="btn-status btn-status__danger"
        shape="round"
        type="ghost"
      >
        {props.children}
      </Button>
    );
  if (type === "processing")
    return (
      <Button
        className="btn-status btn-status__pending"
        shape="round"
        type="ghost"
      >
        {props.children}
      </Button>
    );
  if (type === "default")
    return (
      <Button
        className="btn-status btn-status__pending"
        shape="round"
        type="ghost"
      >
        {props.children}
      </Button>
    );
  if (type === "warning")
    return (
      <Button
        className="btn-status btn-status__warning"
        shape="round"
        type="ghost"
      >
        {props.children}
      </Button>
    );
  return null;
};
ButtonStatus.propTypes = {
  children: PropTypes.element,
  type: PropTypes.string,
  status: PropTypes.string,
};
